String.prototype.toHHMMSS = function () {
  var sec_num = parseInt(this, 10); // don't forget the second param
  if(isNaN(sec_num)){
    return "-"
  }
  var hours   = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   < 10) {hours   = hours;}
  if (minutes < 10) {minutes = minutes;}
  if (seconds < 10) {seconds = seconds;}
  return (hours != "00" ? hours+'h ' : "") + minutes+ "min " + seconds + "s";
}


// let totalTime = 0;

// dum.map((a)=> {
//   totalTime += a.time
// });
// let totalVTime = ((totalTime * 0.4) + "").toHHMMSS();
// let totalQTime = ((totalTime * 0.5) + "").toHHMMSS();
// totalTime = (totalTime + "").toHHMMSS()


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import R from 'ramda';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';

import Section from 'react-bulma-companion/lib/Section';
import Container from 'react-bulma-companion/lib/Container';
import Title from 'react-bulma-companion/lib/Title';

import styles from './styles.module.css';
import '../../../main.css';

import { render } from "react-dom";
import { VictoryBar, VictoryChart, VictoryAxis,
  VictoryTheme } from 'victory';



export default function HomePage() {
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));
  const [data, setData] = useState({});
  const [propNames, setPropNames] = useState([]);
  const [dataViz,setDataViz] = useState(h24(0,"h").map(a => ({hour: a, count: 0})));
  const [usersData,setUsersData] = useState([]);
  const [fltData,setFltData] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [totalITime, setTotalITime] = useState(0);
  const [totalVTime, setTotalVTime] = useState(0);
  const [totalQTime, setTotalQTime] = useState(0);

  const [filters, setFilters] = useState({});
  const [teamList, setTeamList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [themeList, setThemeList] = useState([]);

  const totalQuestionAvailable = 79;
  const totalQuestAvailable = 32;


  const labels = {
    avgTime: "Average Time",
    visits:	"Total Visits",
    uVisits: "Unique Visits",
    click_url: "Url clicks",
    click_quiz:	"Quiz clicks",
    click_quiz_finish: "Quiz Finish",
    click_quiz_Starts: "Quiz starts",
    click_video: "Video starts",
    click_npc: "Npc Clicks"
  }
  function showRaw(ev){
    let targ = ev.target.parentElement.getElementsByClassName("hideHov")[0];
    let divShadow = ev.target.parentElement.getElementsByClassName("divShadow")[0];
    targ.style.display = targ.showed ? "none" : "inline-block";
    ev.target.style.background = targ.showed ? "" : "white";
    divShadow.style.height = targ.showed ? "0px" : getComputedStyle(targ).height;
    targ.showed = !targ.showed; 
  }
  function h24(start,format){
    let hrs = [];
    let ini = (new Date().setMinutes(0,0,0)) + 3600000;
    
    if(format=="h")
        hrs.push(new Date(ini).getHours() + "H");
    else
        hrs.push(new Date(ini));
    for(var i = 0; i < 23; i++){
        ini -= 3600000;
        if(format=="h"){
            hrs.push(new Date(ini).getHours() + "H");
        }else
            hrs.push(new Date(ini));
    }
    return hrs.reverse();
  }

  function fakeUser(){
    let teams = {"Merchandising":["Chef de rayon","Etalagiste",""],
    "Vente":"",
    "Service Client":""
  };
    let jobs = ["Chef de rayon","Vendeur","Agent de caisse","Employé polyvalent"]
    let locations = ["Montparnasse","Gare de Lyon","Châtelet"]
    let themes = ["Merchandising","Vente","Economique","Web"]
  }

  function statParamDiv(param,label,ob){
    let parm = statParam(param,label,ob);
    return parm ? <div className='widthAuto'>{statParam(param,label,ob)}</div> : ""
  }
  function statParam(param,label,ob){

    if(ob[param].length !== undefined && ob[param].map) {
      if(ob[param].length)
        return <div>{label}<br/>{ob[param].map(a => <div className='listElem'>{a}</div>)}</div>
      return ""
    }
    if(ob[param]){
      if(typeof (ob[param]) == "number"){
        ob[param] = parseInt(ob[param])
      }
      return label + " " + ob[param];
    }
    return ""
  }

  function searchUser(val,fromBtn){
    //console.log(val);
    let iSelected = val.target.getAttribute("filterSelect") ? val.target.getAttribute("name") : null;
    if(iSelected){
      if(!filters[iSelected])
        filters[iSelected] = {}
      console.log(filters);
      filters[iSelected].index = val.target.options.selectedIndex;
      filters[iSelected].value = val.target.options[val.target.options.selectedIndex].value;
      setFilters(filters);
      if(filters[iSelected].value == "nofilter"){
        delete filters[iSelected];
        setFilters(filters);
        if(!Object.keys(filters).length){
          console.log(filters)
          setFltData(usersData);
        return 0;
        }
      }
    }
    val = !fromBtn ? val.target.value : val;

    let filterList = Object.keys(filters);
    let match = [];
    let res = [];
    
    
    usersData.map(usr => {
      if(iSelected){
        let Allmatch = filterList.length;
        filterList.map(fname => {
          console.log(usr[fname] + " ?? " + filters[fname].value )
          filters[fname].value != usr[fname] ? Allmatch-- : 0; 
        })
        if(Allmatch == filterList.length){
          let fusr = {...usr}
          if(!match.includes(usr.userId)){
            match.push(usr.userId);
            res.push(fusr);
          }
        }
      }else{
        for (const [key, value] of Object.entries(usr)) {
          if((value + "").toLowerCase().includes(val.toLowerCase()) && ["firstName","lastName","email","job"].includes(key)){
            let fusr = {...usr}
            fusr.hl = key + ': ' + value;
            if(!match.includes(usr.userId)){
              match.push(usr.userId);
              res.push(fusr);
            }
          }
        }
     }
    })
  

    setFltData(res);
  }
  useEffect(() => {
    if (R.isEmpty(user)) {
      dispatch(push('/login'));
    }
    if(!usersData.length){
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ fetchall: true })
    };
    let uri = window.location;
    fetch(uri.protocol + '//' + uri.host+'/api/uaction/stats', requestOptions)
    .then(response => response.json())
    .then(data => {
      //data.usersData = dum;
      console.log(data.usersData)
      // data.results = {
      //   "avgTime": 49,
      //   "visits": 1564,
      //   "uVisits": 2,
      //   "ping_scene": 1449,
      //   "click_video": 35,
      //   "success_quest": 8,
      //   "click_quiz": 30 * Math.floor(Math.random() * 50),
      //   "goodAnswerRate": 68,
      //   "totalAnswerRate": 49,
      //   "totalQuestDone": 30 * 8,
      //   "questDoneRate": 80
      // }
      // faking data for users befor saving state
      // data.usersData = data.usersData.map(a => {
      //   let tim = 10000 + Math.floor(Math.random() * 20000);
      //   let vidTime = Math.floor(Math.random() * tim)
      //   let infoTime = Math.floor(Math.random() * (tim - vidTime));
      //   let quizTime = Math.floor(Math.random() * (tim - vidTime - infoTime));
      //   console.log(tim,vidTime,infoTime,quizTime);
      //   let ftimes = [vidTime,infoTime,quizTime];
      //   for(var i = 0; i < Math.random() * 100; i++){
      //       ftimes.push(ftimes[0]);
      //       ftimes.shift();
      //   }
      //   // replace keyword in email by other random keywords
      //   a.email = a.email.split("@")[0] + ["@*******.com","@******.net","@*****.fr"][Math.floor(Math.random() * 3)];

      //   a.activeUser = true;
      //   a.time = tim;
      //   a.questDone = [1,1,1,1,1,1,1,1,1,1];
      //   a.videos = Array(20).fill(0).map((a,i) => i).filter(a => Math.random() < 0.8);
      //   a.quizQuestionList = Array(62).fill(0).map((a,i) => i).filter(a => Math.random() < 0.8);
      //   a.goodAnswerRate = 30 + Math.floor(Math.random() * 32 );

      //   a.visits = 20 + Math.floor(Math.random() * 60);
      //   a.videosClicks = 20 + Math.floor(Math.random() * 30);
      //   a.quizStarted = 50 + Math.floor(Math.random() * 100);
      //   a.videoTime = vidTime;
      //   a.quizTime = quizTime;
      //   a.infoTime = infoTime;
      //   return a;
      // })


      setData(data.results);
      setUsersData(data.usersData);
      let totalTimes = 0;
      let totalVideoTime = 0;
      let totalQuizTime = 0;
      let totalInfoTime = 0;
      let activeUser = 0;
      //let jobList = [];
      //let teamList = [];
      //let locationList = [];
      


        

      data.usersData.map(a => {
        a.activeUser ? activeUser++ : 0;
        a.time ? totalTimes += a.time : 0;
        a.videoTime ? totalVideoTime += a.videoTime : 0;
        a.quizTime ? totalQuizTime += a.quizTime : 0;
        a.infoTime ? totalInfoTime += a.infoTime : 0;
        a.job && !jobList.includes(a.job) ? jobList.push(a.job) : 0;
        a.teamName && !teamList.includes(a.teamName) ? teamList.push(a.teamName) : 0;
        a.location && !locationList.includes(a.location) ? locationList.push(a.location) : 0;
      })

      setTotalTime(totalTimes)
      setTotalVTime(totalVideoTime)
      setTotalQTime(totalQuizTime);
      setTotalITime(totalInfoTime);
      setJobList(jobList);
      setTeamList(teamList);
      setLocationList(locationList);


      setDataViz(h24(0,"h").map(a => {
          return {hour: a, count: data.h24[a]}
        }
      ))
      setPropNames(Object.keys(data.results));
      charting();
    });
    }
  }, [dispatch, user]);

  return (data ?
    <div className={styles.root}>
      <Section>
        <Container className="display-none">
          <Title size="1">
            Stats
          </Title>
          <div className="columns grayback">
          <div className="column ml-40 displayNone">
          <h1 className="mt-40">Total counts</h1><br/>
          <div className="inline-block">
            <table className="table is-striped is-fullwidth">
              <thead>
                <tr>
                  <th><abbr title="Position">Pos</abbr></th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
              {data ? propNames.map((a) =>{
                if(labels[a])
                  return <tr>
                    <th>{labels[a]}</th>
                    <td>{data[a]}</td>
                  </tr>
                else{
                  //console.log("missing field:" + a);
                }
              }
              ):""}
              </tbody>
            </table>
          </div>
          </div>
        <div class="column is-three-quarters">
        <h1 className="mt-40 titleChart textAlignCenter">Last 24 hours</h1><br/>
        <VictoryChart
            theme={VictoryTheme.material}
            domainPadding={20}
            height={200}
          >
        <VictoryAxis
          tickFormat={h24(0,"h")}
          style={{ tickLabels: { fontSize: 5, fill: "#cccccc"} }}
          fixLabelOverlap={true}
        />
        <VictoryAxis
        
          dependentAxis
          tickFormat={(x) => x}
          orientation="left"
          style={{ tickLabels: { fontSize: 5, fill: "#cccccc" } }}
        />
        <svg>
          <defs>
            <linearGradient id="myGradient" 
              x1="0%" y1="0%" x2="0%" y2="100%"
            >
              <stop offset="0%"   stopColor="rgb(253, 110, 117)"/>
              <stop offset="50%"   stopColor="rgb(254, 90, 226)"/>
              <stop offset="100%" stopColor="rgb(83, 145, 255)"/>
            </linearGradient>
          </defs>
        </svg>
        <VictoryBar
          data={dataViz}
          style={{ data: { fill: "url(#myGradient)" } }}
          x="hour"
          y="count"
        />
      </VictoryChart>
      </div>
      </div>
        </Container>
      </Section>

      <Section>
        <Container className={styles.grayback}>
              <Title size="1">Suivi global</Title>
              <div className='mb-20'>
                {/*<div class="btnFilter" typ="team">Theme Global</div>
                 <div class="btnFilter opaLow" typ="team">Merchandising</div>
                <div class="btnFilter opaLow" typ="team">Economique</div>
                <div class="btnFilter opaLow" typ="team">Vente</div> */}
              </div>
              <div className='mb-20 textWhite textAlignLeft fs16 inline-block'>
              <div className='userHalf' style={{width:"380px"}}>
                      <h1>Participation</h1><br />
                      <h2>Temps</h2>
                      Temps de visite total: <b>{totalTime.toString().toHHMMSS()}</b><br/>
                      Temps passé en Quiz: <b>{totalQTime.toString().toHHMMSS()}</b><br/>
                      Temps total de vidéo: <b>{totalVTime.toString().toHHMMSS()}</b><br/><br/>
                      <h2>Datas</h2>
                      Nombre de connexion: <b>{data.visits || 0}</b><br/>
                      Nombre de vidéos regardées en moyenne: <b>{data.click_video || 0}</b><br/>
                    </div>
                    <div className='userHalf' style={{width:"380px"}}>
                      <h1>Performance</h1><br />
                      <h2>Quiz</h2>
                      Questions répondues en moyenne: <b>{ data.totalAnswerRate }/{totalQuestionAvailable}</b><br/>
                      <div className='percBarBG'>
                        <div className='percBar' style={{"width": (data.totalAnswerRate || 0) * 100 / totalQuestionAvailable + "%"}}></div>
                      </div>
                      <b className='percTxt'>{ data.totalAnswerRate ? ((data.totalAnswerRate || 0) * 100 / totalQuestionAvailable).toFixed(2): 0 }%</b><br /><br />
                      Taux de bonnes réponses moyen :<br/>
                      <div className='percBarBG'>
                        <div className='percBar' style={{ "width": data.goodAnswerRate + "%" }}></div>
                      </div><b className='percTxt'>{data.goodAnswerRate}%</b>
                      <br /> <br />
                      <h2>Quêtes</h2>
                      Taux de Quêtes complétées moyen:
                      <div className='percBarBG'>
                        <div className='percBar' style={{ "width": (data.questDoneRate || 0) * 100 / totalQuestAvailable  + "%" }}></div>
                      </div><b className='percTxt'>{ ((data.questDoneRate || 0) * 100 / totalQuestAvailable).toFixed(2) }%</b>
                    </div>
              </div>
        </Container>
      </Section>

      <Section>
      <Container className={styles.grayback}>
          <Title size="1">
            Suivi individuel
          </Title>
          <div class="mb-20">
          <FontAwesomeIcon icon={faSearch} className="searchGlass" size="lg" />
            <input id="search" onChange={searchUser} type="text" class="searchBar"/>

            <select filterSelect="true" onChange={searchUser} class="btnFilter" name="teamName" id="equipeFilter" value={filters.teamName ? filters.teamName.value : ""}>
              <option value="nofilter" >Equipe</option>
              {teamList.map( (a,i) => {
                return <option value={a} >{a}</option>
              })}
            </select>

            <select filterSelect="true" onChange={searchUser} class="btnFilter" name="job" id="equipeFilter" value={filters.job ? filters.job.value : ""}>
              <option value="nofilter" >Poste</option>
              {jobList.map( (a,i) => {
                return <option value={a} >{a}</option>
              })}
            </select>

            <select filterSelect="true" onChange={searchUser} class="btnFilter" name="location" id="equipeFilter" value={filters.location ? filters.location.value : ""}>
              <option value="nofilter" >Magasin</option>
              {locationList.map( (a) => {
                return <option value={a} >{a}</option>
              })}
            </select>

          </div>
          <table>
            
            <tr className='userData'>
            <th>Plus</th><th>Identité</th><th>Email</th><th>Poste</th><th>Visites</th><th>Temps passé</th>{/*<th>Click Url</th>*/}<th>Click Video</th><th>Quiz Commencés</th>
            </tr>
            {(fltData||usersData).map(a => {
            return (<tr className='userData bbottom'>
              <td className='relative'>
                <div className='btnPlus' onClick={showRaw}>
                  +
                </div>
                <div className='hideHov'>
                  {/*
                  {statParamDiv("visits","Visites: ",a)}
                  {statParamDiv("time","Temps de log: ",a)}
                  {statParamDiv("urlClicks","Nombre click url: ",a)}
                  {statParamDiv("urls","Liste des Urls: ",a)}
                  {statParamDiv("videosClicks","Nombre click video: ",a)}
                  {statParamDiv("videos","Liste des Videos: ",a)}
                  {statParamDiv("quizStarted","Nombre de Quiz commencés: ",a)}
                  {statParamDiv("quizFinished","Nombre de Quiz finis: ",a)}
                  {statParamDiv("quizList","Liste des Quizs: ",a)}
                  */}
                    <div className='themeBar'>
                      {/* <div class="btnFilter" typ="team">Theme Global</div>
                      <div class="btnFilter opaLow" typ="team">Merchandising</div>
                      <div class="btnFilter opaLow" typ="team">Economique</div>
                      <div class="btnFilter opaLow" typ="team">Vente</div> */}
                    </div>
                    <div className='userHalf'>
                      <h1>Participation</h1>
                      <h2>Temps</h2>
                      Temps de visite total: <b>{statParam("time","",a).toHHMMSS()}</b><br/>
                      Temps total de vidéo: <b>{(`${a.videoTime}`).toHHMMSS()}</b><br/><br/>
                      <h2>Datas</h2>
                      Nombre de connexion: <b>{a.visits}</b><br/>
                      Videos Regardée: <b>{a.videos.length}</b><br/>
                    </div>
                    <div className='userHalf'>
                      <h1>Performance</h1>
                      <h2>Quiz</h2>
                      Questions répondues: <b>{a.quizQuestionList?.length}/{totalQuestionAvailable}</b><br/>
                      <div className='percBarBG'>
                        <div className='percBar' style={{"width":a.quizQuestionList.length + "%"}}></div>
                      </div>
                      <b className='percTxt'>{ ((a.quizQuestionList.length * 100) / totalQuestionAvailable).toFixed(2) }%</b><br />
                      Taux de bonne réponse sur le dernier ensemble:<br/>
                      <div className='percBarBG'>
                        <div className='percBar' style={{"width":(a.goodAnswerRate || 0) + "%"}}></div>
                      </div><b className='percTxt'>{a.goodAnswerRate || 0}%</b>
                      <br /> <br />
                      <h2>Quêtes</h2>
                      Quêtes complétées: {a.questDone.length} / {totalQuestAvailable}
                      <br />
                      <div className='percBarBG'>
                        <div className='percBar' style={{ "width": ((a.questDone.length * 100) / totalQuestAvailable).toFixed(2) + "%" }}></div>
                      </div><b className='percTxt'>{ ((a.questDone.length * 100) / totalQuestAvailable).toFixed(2) }%</b>
                    </div>
                  </div>
                <div className="divShadow"></div>
              </td>
              <td>{a.lastName + " " + a.firstName}</td>
              <td>{a.email}</td>
              <td>{a.job}</td>
              <td>{statParam("visits","",a) || "-"}</td>
              <td>{statParam("time","",a).toHHMMSS() || "-"}</td>
              {/* <td>{statParam("urlClicks","",a) || "-"}</td> */}
              <td>{statParam("videosClicks","",a) || "-"}</td>
              <td>{statParam("quizStarted","",a) || "-"}</td>
            </tr>
            )
          })}
          </table>
      </Container>
      </Section>
    </div>
  : <h1>No data Found</h1>);
}
