// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomePage-root--O4IxG {\n  text-align: center;\n}\n\ntbody{\n  font-size: 20px;\n}\n\n.HomePage-centerAlign--T7KbA{\n  display: flex !important;\n  align-items: center !important;\n}\n.HomePage-UserData--jW1er{\n  display: inline-block;\n  width: 100%;\n  margin: 15px;\n  padding: 20px;\n  background: #212529;\n  color: white;\n}\n/*\nbody{\n  background:#1a1c1f\n}\n\n.grayback{\n  background:#212529;\n  border-radius: 14px;\n  border: 1px solid rgba(255, 255, 255, 0.1);\n  color:white !important;\n}\n*/\n", "",{"version":3,"sources":["webpack://./client/components/pages/HomePage/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,8BAA8B;AAChC;AACA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;AACA;;;;;;;;;;;CAWC","sourcesContent":[".root {\n  text-align: center;\n}\n\ntbody{\n  font-size: 20px;\n}\n\n.centerAlign{\n  display: flex !important;\n  align-items: center !important;\n}\n.UserData{\n  display: inline-block;\n  width: 100%;\n  margin: 15px;\n  padding: 20px;\n  background: #212529;\n  color: white;\n}\n/*\nbody{\n  background:#1a1c1f\n}\n\n.grayback{\n  background:#212529;\n  border-radius: 14px;\n  border: 1px solid rgba(255, 255, 255, 0.1);\n  color:white !important;\n}\n*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "HomePage-root--O4IxG",
	"centerAlign": "HomePage-centerAlign--T7KbA",
	"UserData": "HomePage-UserData--jW1er"
};
export default ___CSS_LOADER_EXPORT___;
