import request from 'superagent';
import { handleSuccess, handleError } from '_utils/api';

export const postCheckUsername = username =>
  request.post('/api/users/checkusername')
    .send({ username })
    .then(handleSuccess)
    .catch(handleError);

export const postUaction = () =>
request.post('/api/users/uaction')
  .send('ok')
  .then(handleSuccess)
  .catch(handleError);
