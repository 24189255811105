import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import R from 'ramda';

import { validateName } from '_utils/validation';
import { attemptGetUser, attemptUpdateUser, notif } from '_store/thunks/user';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons/faRotate';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons/faPenToSquare';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';

import Section from 'react-bulma-companion/lib/Section';
import Container from 'react-bulma-companion/lib/Container';
import Title from 'react-bulma-companion/lib/Title';
import Columns from 'react-bulma-companion/lib/Columns';
import Column from 'react-bulma-companion/lib/Column';
import Box from 'react-bulma-companion/lib/Box';
import Icon from 'react-bulma-companion/lib/Icon';
import Button from 'react-bulma-companion/lib/Button';
import Image from 'react-bulma-companion/lib/Image';
import Field from 'react-bulma-companion/lib/Field';
import Control from 'react-bulma-companion/lib/Control';
import Textarea from 'react-bulma-companion/lib/Textarea';
import Label from 'react-bulma-companion/lib/Label';
import Help from 'react-bulma-companion/lib/Help';
import Input from 'react-bulma-companion/lib/Input';


import styles from './styles.module.css';
import '../../../main.css';
import { el, fi } from 'date-fns/locale';



export default function UseListPage(props) {
  props = {};
  props.prefilled = {
    // "idd":"randomId",
    // "questionTitle": "bonjour c'est une question",
    // "choice1": "a ",
    // "choice2": "b",
    // "choice3": "c",
    // "choice4": "ddd",
    "goodAnswer": null
}
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));
  const [fields, setFields] = useState(props && props.prefilled ? {...props.prefilled} : {});
  const [reloadState, setReloadState] = useState(Math.random());
  const [editFields, setEditFields] = useState({});
  const [limitChars, setLimitChars] = useState({});

  const [userList, setUserList] = useState([]);
  const [showEdit, setShowEdit] = useState('');
  
  let [customQuizList, setCustomQuizList] = useState([]);

  const [contents, setContents] = useState([]);
  const [selectedContent, setSelectedContent] = useState({});

  const [teamList, setTeamList] = useState([]);

  const [questionGen, setQuestionGen] = useState({});
  const [questionResults, setQuestionResults] = useState([]);
  const [questionLoading, setQuestionLoading] = useState(false);

  const [questionTopic, setQuestionTopic] = useState([]);
  const [quizs, setQuizs] = useState({});
  const [addField, setAddField] = useState({});
  const [prefilled, setPrefilled] = useState(false);

  let [keepTheme, setKeepTheme] = useState(false);
  const [questionList, setQuestionList] = useState([]);




  const toggleQuizList = (ev) => {
    // this element is a checkbox
    ev.stopPropagation();
    ev.preventDefault();
    let idd = ev.currentTarget.getAttribute("questionTopicId");
    // if this is already checked then remove from list
    if(customQuizList.includes(idd)){
      customQuizList = customQuizList.filter(a => a != idd);
      setCustomQuizList(customQuizList);
    }else{
      customQuizList.push(idd);
      setCustomQuizList(customQuizList);
    }
    setTimeout(function(){
      setReloadState(Math.random());
    },100)

  }

  const hello = ()=>{
    console.log(fields)
  };
  // function generate id
  const generateId = () => {
    return Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9);
  };
  const resetState = useCallback(() => {
    if(props && props.prefilled && !prefilled){
      setPrefilled(true);
      setFields(fields);
    }else
      setFields({});
  }, [user]);

  const clearFields = () => {
      fields["goodAnswer"] = null;
      fields["questionTitle"] = "";

      if(!keepTheme){
        fields["questionTopic"] = "";
        fields["questionTopicId"] = "";
      }
      
      fields["choice1"] = "";
      fields["choice2"] = "";
      fields["choice3"] = "";
      fields["choice4"] = "";
      fields["editMode"] = false;
      fields["questionId"] = "";
      fields["idd"] = "";
      setFields(fields);
  }
  useEffect(() => {
    resetState();
  }, [user, resetState, reloadState]);

  const showInput = e => {
    let nextInput = e.target.nextSibling;
    if(!e.target.showed){
      e.target.showed = true;
      nextInput.classList.remove("display-none");
    }else{
      e.target.showed = false;
      nextInput.classList.add("display-none");
    }
  }

  const updateCheckBox = e => {
    console.log('updateCheckBox')
    const setterName = e.target.getAttribute("settername");
    const checkboxs = document.getElementsByClassName("checkboxQuestion");
    
    if(e.target.checked){
      // uncheck all other
      if(fields.goodAnswer != null){
        checkboxs[fields.goodAnswer * 1].checked = false;
      }
      updateField(e);
    }else{
      if(fields.goodAnswer == e.target.value){
        fields.goodAnswer = null;
        setFields(fields);
      }
    }
  }



  const packQuestion = e => {
    let question = {};
    question.questionChoices = [];
    let errors = [];
    ["choice1","choice2","choice3","choice4"].map(a => {
      if(fields[a] && fields[a].trim()){
        question.questionChoices.push(fields[a].trim());
      }else{
        errors.push("Veuillez remplir les 4 choix");
      }
    })
    if(fields.goodAnswer != null){
      question.goodAnswer = fields.goodAnswer;
      question.questionChoices[fields.goodAnswer * 1] += " ";
    }else{
      errors.push("Veuillez sélectionner une bonne réponse");
      
    }
    if(fields.questionTitle && fields.questionTitle.trim()){
      question.questionTitle = fields.questionTitle.trim();
    }else{
      errors.push("Veuillez remplir la question");
    }
    if(fields.questionTopicId && fields.questionTopicId.trim()){
      question.questionTopicId = fields.questionTopicId.trim();
    }else{
      if(fields.questionTopic && fields.questionTopic.trim()){
        question.questionTopic = fields.questionTopic.trim();
      }else{
        errors.push("Veuillez remplir le thème");
      }
    }
    if(errors.length){
      notif(errors[0],"danger");
      console.log(errors)
      return false;
    }
    if(!fields.idd){
      question.idd = generateId();
      fields["idd"] = question.idd;
      setFields(fields);
    }
    let foundIndex = -1;
    if(questionList.filter((a,i) => {a.idd == question.idd}).length){

    }else{
      questionList.push(question);
      setQuestionList(questionList);
    }
    console.log(question)
    if(fields.editMode){
      question.editMode = true;
    }
    // post question 
    if(question.editMode){
      question.questionId = fields.questionId;
      postData(question,'question/edit-question',function(lol){
        clearFields();
        postData({getAll:true},'question/get-quiz',cbackSetter);
      })
    }else{
      postData(question,'question/add-question',function(lol){
        clearFields();
        postData({getAll:true},'question/get-quiz',cbackSetter);
      })
    }
    return question;
  }
  
  const updateField = e => {

      // check limit chars
      checkLimitChars(e);
      const setterName = e.target.getAttribute("settername");
      const isEditField = e.target.getAttribute("editfield");

      if(!isEditField){
        fields[setterName] = e.target.value;
        setFields(fields);
      }else{
        if(editFields.userId != isEditField){
          Object.keys(editFields).map(k => editFields[k] = '');
          userList.map(a => {
            if(a.userId == isEditField){
              Object.keys(a).map(k => editFields[k] = a[k]);
            }
          })
        }
        editFields[setterName] = e.target.value;
        setEditFields(editFields);
      }
  }

  const checkLimitChars = e => {
    const charLimit = e.target.getAttribute("limitChars");
    // check if the field has limitChars attribute
    if(charLimit){
        // get the number of chars left
        let left = charLimit - e.target.value.length;
        if(left < 0){
          limitChars[e.target.id] = "Max caractères dépassé de: " +  Math.abs(left) + " caractères";
          e.target.style.color = "red";
        }else{
          limitChars[e.target.id] = left +  "/" + charLimit;
          e.target.style.color = "white";
        }
        setLimitChars(limitChars);
    }else{
        limitChars[e.target.id] = "";
        setLimitChars(limitChars);
    }
  }
  const updateGen = e => {
    // check limit chars
    checkLimitChars(e);
    // get value
    questionGen.value = e.target.value;
    setQuestionGen(questionGen);
  }

  const generateQuestion = e => {
    // get value
    if(questionLoading){
      return;
    }
    // if content is selected then generate question based on content


    let value;
    if(selectedContent.contentId){
      value = selectedContent.content;
      // if content is html then extract text
    }else{
      value = questionGen.value;
    }
    if(value && value.trim()){
      setQuestionLoading(true);
      postData({context:value},'question/generate-question',function(data){
        if(data && data.data && data.data.length){
          setQuestionResults(data.data);
          setQuestionLoading(false);
        }
      })
    }else{
      notif("Veuillez selectionner un contenu ou écrire un prompt","danger");
    }
  }
  const copyClipboard = (ev) => {
    navigator.clipboard.writeText(ev.target.innerText);
    notif("Copié dans le presse-papier!","success")
  }
  const refresh = () => dispatch(attemptGetUser())
    .then(resetState)
    .catch(R.identity);

  const getTopicId = (topic) => {
    let id = 0;
    Object.keys(quizs).map(a => {
      if(quizs[a].questionTopic == topic){
        id = a;
      }
    })
    return id;
  }
  const getTopicById = (id) => {
    let topic = "";
    if(quizs[id]){
      topic = quizs[id].questionTopic;
    }
    return topic;
  }

  const cbackSetter = (data) => {
      if(data && data.map){
        data.map(a => {
          a.questionTopic && !questionTopic.includes(a.questionTopic) ? questionTopic.push(a.questionTopic) : 0;
          if(a.questionTopicId){
            quizs[a.questionTopicId] = a;
          }
        })
      }else{
        console.log("no map")
        console.log(data);
      }
      setQuestionTopic(questionTopic);
      setQuizs(quizs);
  }
  const postData = (datas,slug,callback) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(datas) //fields
    };
    fetch(window.location.origin +'/api/' + slug, requestOptions)
    .then(response => response.json())
    .then(data => {
      setTimeout(function(){console.log(userList)},1000)
      if(data)
        callback(data);
      if(data.message)
        notif(data.message,"success");
      if(data.messageError)
        notif(data.messageError,"danger");
    });
  }

  const deleteQuestion = (ev) => {
    ev.stopPropagation();
    let questionId = ev.currentTarget.getAttribute("questionId");
    if(questionId)
      postData({questionId},'question/delete-question',function(lol){
        postData({getAll:true},'question/get-quiz',cbackSetter);
      })
  }

  const deleteQuiz = (ev) => {
    ev.stopPropagation();
    let questionTopicId = ev.currentTarget.getAttribute("questionTopicId");
    console.log(ev.currentTarget)
    console.log(questionTopicId)
    if(questionTopicId)
      postData({questionTopicId},'question/delete-quiz',function(lol){
        postData({getAll:true},'question/get-quiz',cbackSetter);
      })
  }
  const showEditMenu = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    let questionId = ev.currentTarget.getAttribute("questionId");
    let questionTopicId = ev.currentTarget.getAttribute("questionTopicId");
    let fieldSet = {};
    let quest = quizs[questionTopicId].questions.filter(a => a.questionId == questionId)[0];
    Object.keys(quest).map(a => {
      fieldSet[a] = quest[a];
      if(a == "questionChoices"){
        quest[a].map((b,i) => {
          fieldSet["choice"+(i+1)] = b;
        })
      }
    })
    fieldSet.questionId = questionId;
    fieldSet.editMode = true;
    setFields(fieldSet);
  }
  const selectContent = (ev) => {
    let contentId = ev.target.value;
    let content = contents.filter(a => a.contentId == contentId)[0];
    if(content)
      setSelectedContent(content);
    else{
      setSelectedContent({});
    }
  }

  const addGenQuestionToEditMenu = (ev) => {
      ev.stopPropagation();
      ev.preventDefault();
      let fieldSet = {};
      let quest = questionResults[ev.currentTarget.getAttribute("genIndex")];
      Object.keys(quest).map(a => {
        fieldSet[a] = quest[a];
        if(a == "questionChoices"){
          quest[a].map((b,i) => {
            fieldSet["choice"+(i+1)] = b;
          })
        }
      })
      // check if keepTheme is on
      if(!keepTheme){
        fieldSet.questionTopic = "";
        fieldSet.questionTopicId = "";
      }else{
        fieldSet.questionTopic = fields.questionTopic;
        fieldSet.questionTopicId = fields.questionTopicId;
      }
      setFields(fieldSet);
  }

  const keepThemeOn = (ev) => {
    
    if(!keepTheme){
        keepTheme = true;
    }
    else{
        keepTheme = false;
    }
    setKeepTheme(keepTheme);
  }
  const save = (e) => {
    
    let empties = [];
    const isEditField = e.target.getAttribute("editfield");
    ["questionTitle","choice1","choice2","choice3","choice4"].map(fieldName => {
      if(!isEditField && (!fields[fieldName] || !fields[fieldName].trim())){
        empties.push(fieldName);
      }
      if(isEditField && (!editFields[fieldName] || !editFields[fieldName].trim())){
        empties.push(fieldName);
      }
    })
    if (!empties.length) {
      console.log(fields)
      return
      if(!isEditField)
        postData(fields,'question/add-question',cbackSetter);
      else{
        editFields.edit = true;
        postData(editFields,'question/add-question',function(cb){console.log("ok ok ok")});
      }
    }else{
        notif("Les champs Question et choix A/B/C/D sont requit","danger");
    }
  };

  useEffect(() => {
    postData({getAll:true},'question/get-quiz',cbackSetter);
    postData({getAll:true},'content/get-content',function(data){
      if(data && data.map){
        setContents(data);
      }
    })
  },[userList.length]);
  
  return (
    <div className="settings-page page">
      <Section>
        <Container>
          <Columns>
            <Column size="12">
            <div className="profile-settings">
              <div className="account-settings">
              <Box className="general-profile">
        
      <Title size="3">
        Créer une question
      </Title>
      <hr className="separator" />
      <Columns size="12">
        <Column size="6" className="padding-20">
                <Label htmlFor="questionTitle" className="Label">
                  Question (300 caractères maximum)
                </Label>
                <Control>
                  <Textarea
                    id="questionTitle"
                    placeholder="Ecrire une question"
                    settername="questionTitle"
                    value={fields.questionTitle}
                    onChange={updateField}
                    limitChars="300"
                    className="max-width-75 padding-20"
                  />
                </Control>
                <div>{limitChars.questionTitle ? limitChars.questionTitle : ""}</div>
                <br />

                <Label htmlFor="choice1" className="Label">
                  A<input type="checkbox" className='checkboxQuestion inline-block ml-2' settername="goodAnswer" onChange={updateCheckBox} value="0" checked={fields["goodAnswer"] == 0} />
                </Label>
                <Control>
                  <Input
                    id="choice1"
                    placeholder="Ajouter un choix"
                    settername="choice1"
                    value={fields.choice1}
                    onChange={updateField}
                    limitChars="100"
                    className="max-width-75 padding-20"
                  />
                </Control>
                <div>{limitChars.choice1 ? limitChars.choice1 : ""}</div>
                <br />
                <Label htmlFor="choice2">
                  B<input type="checkbox" className='checkboxQuestion inline-block ml-2' settername="goodAnswer" onChange={updateCheckBox} value="1" checked={fields["goodAnswer"] == 1}/>
                </Label>
                <Control>
                  <Input
                    id="choice2"
                    placeholder="Ajouter un choix"
                    value={fields.choice2}
                    settername="choice2"
                    onChange={updateField}
                    limitChars="100"
                    className="max-width-75 padding-20"
                  />
                </Control>
                <div>{limitChars.choice2 ? limitChars.choice2 : ""}</div>
                <br />
                <Label htmlFor="choice3">
                  C<input type="checkbox" className='checkboxQuestion inline-block ml-2' settername="goodAnswer" onChange={updateCheckBox} value="2" checked={ fields["goodAnswer"] == 2}/>
                </Label>
                <Control>
                  <Input
                    id="choice3"
                    placeholder="Ajouter un choix"
                    value={fields.choice3}
                    settername="choice3"
                    onChange={updateField}
                    limitChars="100"
                    className="max-width-75 padding-20"
                  />
                </Control>
                <div>{limitChars.choice3 ? limitChars.choice3 : ""}</div>
                <br />
                <Label htmlFor="choice4">
                  D<input type="checkbox" className='checkboxQuestion inline-block ml-2' settername="goodAnswer" onChange={updateCheckBox} value="3" checked={fields["goodAnswer"] == 3}/>
                </Label>
                <Control>
                  <Input
                    id="choice4"
                    placeholder="Ajouter un choix"
                    value={fields.choice4}
                    settername="choice4"
                    onChange={updateField}
                    limitChars="100"
                    className="max-width-75 padding-20"
                  />
                </Control>
                <div>{limitChars.choice4 ? limitChars.choice4 : ""}</div>
          <Columns>
            <Column size="12" className="padding-20">
                <Field>
                  <Label htmlFor="last-name">
                    Topic List
                  </Label>
                  <Control>
                  <select filterSelect="true" onChange={updateField} class="btnFilter" settername="questionTopicId" id="jobeFilter" value={fields.questionTopicId}>
                    <option value="">Theme</option>
                    {questionTopic.map( (a,i) => {
                      return <option value={getTopicId(a)} >{a}</option>
                    })}
                  </select>
                    <div class="btnFilter bgWhite ml-0" onClick={showInput}>+</div>
                    <Input
                      id="questionTopic"
                      placeholder="Theme"
                      value={fields.questionTopic}
                      settername="questionTopic"
                      onChange={updateField}
                      className="max-width-75 display-none"
                    />
                  </Control>
                  <input type="checkbox" className='checkboxQuestion inline-block ml-2' onChange={keepThemeOn} value="0" checked={ keepTheme }/>
                  <span> Garder le thème actuel</span>

                </Field>
              </Column>
          </Columns>
        </Column>
        <Column size="6" className="padding-20">
                  <Label htmlFor="questionTitle" className="Label">
                    Generer des questions basée sur un contenu
                  </Label>
                  <Control>
                    <select filterSelect="true" onChange={selectContent} class="btnFilter" settername="questionTopicId" id="jobeFilter" value={selectedContent.contentId}>
                      <option value="">Contenu</option>
                      {contents.map( (a,i) => {
                        return <option value={a.contentId} >{a.contentTitle}</option>
                      })}
                    </select>
                  </Control>
                  <div className={ "prevContent " +  (selectedContent.contentId == null ? "display-none" : "")}>
                    { selectedContent.formatedContent || selectedContent.content ? 
                      <div dangerouslySetInnerHTML={{ __html: selectedContent.formatedContent || selectedContent.content}} />
                      : ""
                    }
                  </div>
                  <div className={selectedContent.contentId ? "display-none" : ""}>
                    <Label htmlFor="questionTitle" className="Label">
                      Generer des questions basée sur un prompt
                    </Label>
                    <Control>
                      <Textarea
                        id="genQuestionValue"
                        placeholder="Ecrire une question"
                        settername="genQuestionValue"
                        value={questionGen.value}
                        onChange={updateGen}
                        limitChars="16400"
                        className="max-width-75 padding-20"
                      />
                    </Control>
                    <div>{limitChars.genQuestionValue ? limitChars.genQuestionValue : ""}</div>
                  </div>
                  <Button color="success" className={"mt-20 " + (questionLoading ? "blinkOpa" : "")} onClick={generateQuestion}>Generer nouvelle question</Button>
                  <div className='GenResultsCont' id="GenResultsCont">
                    {questionResults.map((a,i) => {
                      return <div className="GenResult">
                        <div className="GenResultTitle">{a.questionTitle}</div>
                        <div className="GenResultChoices">
                          {a.questionChoices.map((b,j) => {
                            return <div className={"GenResultChoice " + (j == a.goodAnswer ? "goodAnswer" : "")} onClick={copyClipboard}>
                              {b}
                            </div>
                          })}
                        </div>
                        <div class="explaination">
                          {a.explaination}
                        </div>
                        <Button color="success" onClick={addGenQuestionToEditMenu} genIndex={i}>Selectionner</Button>
                      </div>
                    })}
                  </div>
        </Column>
      </Columns>
      <hr className="separator" />
      <Button color="success" onClick={packQuestion} /*disabled={!edited}*/>
      Sauver
      </Button>
    </Box>
              </div>
            </div>
            </Column>
          </Columns>
        </Container>
      </Section>

      <Section>
        <Container>
          <Columns>
          <Column size="12">
            <div className="profile-settings">
              <div className="account-settings">
              <Box className="general-profile">
                <Title size="3">
                  Quiz List
                </Title>
                { customQuizList.length ? <div className="questionListItem">{`https://metaverse.ww.fo/rhinodash/?room=${Math.random().toString(36).substr(2, 9)}&quizset=${customQuizList.join("-")}`}</div> : "" }
                <hr className="separator" />
                <Columns>
                  <Column size="6" className="padding-20">
                    {quizs ? Object.keys(quizs).map( (a) => {
                      a = quizs[a];
                      return <div className="quizTitle" data-idd={a.questionTopicId}>
                        <input type="checkbox" className='checkboxQuestion inline-block ml-2' questionTopicId={a.questionTopicId} onChange={toggleQuizList} value={a.questionTopicId} checked={customQuizList.includes(a.questionTopicId)} />
                        <h2>{a.questionTopic}
                        
                          <div className="inline-block ml-2" onClick={deleteQuiz} questionTopicId={a.questionTopicId} >
                            <FontAwesomeIcon icon={faTrashCan} className='icon'/>
                          </div>
                        </h2>
                        {a.questions.map(b => {
                        return <div className="questionListItem" data-idd={b.questionId}>
                          {b.questionTitle}
                          <div className="inline-block ml-2" onClick={showEditMenu} questionId={b.questionId} questionTopicId={b.questionTopicId}>
                            <FontAwesomeIcon icon={faPenToSquare} className="icon" />
                          </div>
                          <div className="inline-block ml-2" onClick={deleteQuestion} questionId={b.questionId} >
                          <FontAwesomeIcon icon={faTrashCan} className="icon" />
                          </div>
                        </div>
                        })}
                      </div>
                    }) : ""}
                  </Column>
                </Columns>
              </Box>
              </div>
            </div>
            </Column>
          </Columns>
        </Container>
      </Section>
    </div>
  );
}




