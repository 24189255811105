import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import R from 'ramda';

import { validateName } from '_utils/validation';
import { attemptGetUser, attemptUpdateUser, notif } from '_store/thunks/user';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons/faRotate';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons/faPenToSquare';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';

import Section from 'react-bulma-companion/lib/Section';
import Container from 'react-bulma-companion/lib/Container';
import Title from 'react-bulma-companion/lib/Title';
import Columns from 'react-bulma-companion/lib/Columns';
import Column from 'react-bulma-companion/lib/Column';
import Box from 'react-bulma-companion/lib/Box';
import Icon from 'react-bulma-companion/lib/Icon';
import Button from 'react-bulma-companion/lib/Button';
import Image from 'react-bulma-companion/lib/Image';
import Field from 'react-bulma-companion/lib/Field';
import Control from 'react-bulma-companion/lib/Control';
import Textarea from 'react-bulma-companion/lib/Textarea';
import Label from 'react-bulma-companion/lib/Label';
import Help from 'react-bulma-companion/lib/Help';
import Input from 'react-bulma-companion/lib/Input';

import styles from './styles.module.css';
import '../../../main.css';

export default function UseListPage() {
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));
  const [fields, setFields] = useState({});
  const [editFields, setEditFields] = useState({});

  const [userList, setUserList] = useState([]);
  const [showEdit, setShowEdit] = useState('');
  
  const [teamList, setTeamList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [themeList, setThemeList] = useState([]);
  const [addField, setAddField] = useState({});

  const resetState = useCallback(() => {
    setFields({});
  }, [user]);

  useEffect(() => {
    resetState();
  }, [user, resetState]);

  const showInput = e => {
    let nextInput = e.target.nextSibling;
    if(!e.target.showed){
      e.target.showed = true;
      nextInput.classList.remove("display-none");
    }else{
      e.target.showed = false;
      nextInput.classList.add("display-none");
    }
  }
  const updateField = e => {

      const setterName = e.target.getAttribute("settername");
      const isEditField = e.target.getAttribute("editfield");
      if(!isEditField){
        fields[setterName] = e.target.value;
        setFields(fields);
      }else{
        if(editFields.userId != isEditField){
          Object.keys(editFields).map(k => editFields[k] = '');
          userList.map(a => {
            if(a.userId == isEditField){
              Object.keys(a).map(k => editFields[k] = a[k]);
            }
          })
        }
        editFields[setterName] = e.target.value;
        setEditFields(editFields);
      }
  }
  const copyClipboard = (ev) => {
    navigator.clipboard.writeText(ev.target.innerText);
    notif("Copié dans le presse-papier!","success")
  }
  const refresh = () => dispatch(attemptGetUser())
    .then(resetState)
    .catch(R.identity);

  const cbackSetter = (data) => {
      if(data && data.map){
        data.map(a => {
          a.job && !jobList.includes(a.job) ? jobList.push(a.job) : 0;
          a.team && a.team.teamName && !teamList.includes(a.team.teamName) ? teamList.push(a.team.teamName) : 0;
          a.team && a.team.location && !locationList.includes(a.team.location) ? locationList.push(a.team.location) : 0;
        })
      }else{
        console.log("no map")
        console.log(data);
      }
      
      setJobList(jobList);
      setTeamList(teamList);
      setLocationList(locationList);

      console.log(jobList,teamList,locationList)
      setUserList(data);
  }
  const postData = (datas,slug,callback) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(datas) //fields
    };
    fetch(window.location.origin +'/api/' + slug, requestOptions)
    .then(response => response.json())
    .then(data => {
      setTimeout(function(){console.log(userList)},1000)
      if(data)
        callback(data);
      if(data.message)
        notif(data.message,"success");
      if(data.messageError)
        notif(data.messageError,"danger");
    });
  }

  const deleteUser = (ev) => {
    let userId = ev.target.getAttribute("userid");
    console.log(userId);
    if(userId)
      postData({userId},'dclusers/delete-dcluser',function(lol){
        postData({getAll:true},'dclusers/get-dclusers',cbackSetter);
      })
  }
  const showEditMenu = (ev) => {
    let uid = ev.target.getAttribute("userid");
    if(showEdit != uid)
      setShowEdit(uid);
    else
      setShowEdit('');
  }
  const save = (e) => {
    let empties = [];
    const isEditField = e.target.getAttribute("editfield");
    ["firstName","lastName","email"].map(fieldName => {
      if(!isEditField && (!fields[fieldName] || !fields[fieldName].trim())){
        empties.push(fieldName);
      }
      if(isEditField && (!editFields[fieldName] || !editFields[fieldName].trim())){
        empties.push(fieldName);
      }
    })
    if (!empties.length) {
      if(!isEditField)
        postData(fields,'dclusers/add-dcluser',cbackSetter);
      else{
        editFields.edit = true;
        postData(editFields,'dclusers/add-dcluser',function(cb){console.log("ok ok ok")});
      }
    }else{
      
        notif("Les champs Nom, Prénom et Email sont requis","danger");
      
    }
  };

  useEffect(() => {
    postData({getAll:true},'dclusers/get-dclusers',cbackSetter);
  },[userList.length]);
  
  return (
    <div className="settings-page page">
      <Section>
        <Container>
          <Columns>
            <Column size="12">
            <div className="profile-settings">
              <div className="account-settings">
              <Box className="general-profile">
      <Icon size="medium" pull="right" onClick={refresh} onKeyPress={refresh}>
        <FontAwesomeIcon icon={faRotate} size="lg" />
      </Icon>
      <Title size="3">
        Créer un utilisateur
      </Title>
      <hr className="separator" />
      <Columns>
        <Column size="8">
          <Columns>
            <Column size="6" className="padding-20">
              <Field>
                <Label htmlFor="first-name" className="Label">
                  Prénom
                </Label>
                <Control>
                  <Input
                    id="first-name"
                    placeholder="Prénom"
                    settername="firstName"
                    value={fields.firstName}
                    onChange={updateField}
                    className="max-width-75"
                  />
                </Control>
              </Field>
            </Column>
            <Column size="6" className="padding-20">
              <Field>
                <Label htmlFor="last-name">
                  Nom
                </Label>
                <Control>
                  <Input
                    id="last-name"
                    placeholder="Nom"
                    value={fields.lastName}
                    settername="lastName"
                    onChange={updateField}
                    className="max-width-75"
                  />
                </Control>
              </Field>
            </Column>
            <Column size="6" className="padding-20">
              <Field>
                <Label htmlFor="last-name">
                  Email
                </Label>
                <Control>
                  <Input
                    id="Email"
                    placeholder="Email"
                    value={fields.email}
                    settername="email"
                    onChange={updateField}
                    className="max-width-75"
                  />
                </Control>
              </Field>
            </Column>
          </Columns>
          <Columns>
            <Column size="6" className="padding-20">
                <Field>
                  <Label htmlFor="last-name">
                    Poste
                  </Label>
                  <Control>
                  <select filterSelect="true" onChange={updateField} class="btnFilter" settername="job" id="jobeFilter" value={fields.job}>
                    <option value="">Poste</option>
                    {jobList.map( (a,i) => {
                      return <option value={a} >{a}</option>
                    })}
                  </select>
                    <div class="btnFilter bgWhite ml-0" onClick={showInput}>+</div>
                    <Input
                      id="job"
                      placeholder="Poste"
                      value={fields.job}
                      settername="job"
                      onChange={updateField}
                      className="max-width-75 display-none"
                    />
                  </Control>
                </Field>
              </Column>
              <Column size="6" className="padding-20">
                <Field>
                  <Label htmlFor="last-name">
                    Equipe
                  </Label>
                  <Control>
                  <select filterSelect="true" onChange={updateField} class="btnFilter" settername="teamName" id="equipeFilter" value={fields.teamName}>
                    <option value="">Equipe</option>
                    {teamList.map( (a,i) => {
                      return <option value={a} >{a}</option>
                    })}
                  </select>
                    <div class="btnFilter bgWhite ml-0" onClick={showInput}>+</div>
                    <Input
                      id="team"
                      placeholder="Equipe"
                      value={fields.teamName}
                      settername="teamName"
                      onChange={updateField}
                      className="max-width-75 display-none"
                    />
                  </Control>
                </Field>
              </Column>
              <Column size="6" className="padding-20">
                <Field>
                  <Label htmlFor="last-name">
                    Magasin
                  </Label>
                  <Control>
                  <select filterSelect="true" onChange={updateField} class="btnFilter" settername="location" id="locationFilter" value={fields.location}>
                    <option value="">Magasin</option>
                    {locationList.map( (a,i) => {
                      return <option value={a} >{a}</option>
                    })}
                  </select>
                    <div class="btnFilter bgWhite ml-0" onClick={showInput}>+</div>
                    <Input
                      id="location"
                      placeholder="Magasin"
                      value={fields.location}
                      settername="location"
                      onChange={updateField}
                      className="max-width-75 display-none"
                    />
                  </Control>
                </Field>
              </Column>
          </Columns>
        </Column>
      </Columns>
      <hr className="separator" />
      <Button color="success" onClick={save} /*disabled={!edited}*/>
      Sauver
      </Button>
    </Box>
    
    { userList.length ? userList.map(a => 
      <Box className="padding-bottom-5">
        <Columns>
          <Column size="2" className="padding-20">
            {a.firstName}
          </Column>
          <Column size="2" className="padding-20">
            {a.lastName}
          </Column>
          <Column size="2" className="padding-20">
            {a.email}
          </Column>
          <Column size="4" className="padding-20 pointer-click" onClick={copyClipboard}>
            {window.location.origin}:8000/?position=-20%2C-133&identity={a.userId} <FontAwesomeIcon icon={faCopy}/>
          </Column>
          <Column size="2">
            <Button onClick={deleteUser.bind(this)} userid={a.userId} className="is-pulled-right is-danger margin-left-15"><FontAwesomeIcon className="no-click" icon={faTrashCan}/></Button>
            <Button onClick={showEditMenu.bind(this)} userid={a.userId} className="is-pulled-right"><FontAwesomeIcon icon={faPenToSquare} className="no-click"/></Button>
          </Column>
        </Columns>
        
        <Columns className={a.userId == showEdit ? '' : 'display-none'}>
          <Column size="4" className="padding-20">
          <hr className="separator" />
              <Label>
                    Prénom
              </Label>
            <Control>
              <Input
                placeholder={a.firstName}
                value={editFields["userId"] == a.userId ? editFields["firstName"] :  a.firstName}
                editfield={a.userId}
                settername="firstName"
                onChange={updateField}
              />
            </Control>
            <Label>
                    Nom
              </Label>
            <Control>
              <Input
                placeholder={a.lastName}
                value={editFields["userId"] == a.userId ? editFields["lastName"] :  a.lastName}
                editfield={a.userId}
                settername="lastName"
                onChange={updateField}
              />
            </Control>
            <Label>
                    Email
              </Label>
            <Control>
              <Input
                placeholder={a.email}
                value={editFields["userId"] == a.userId ? editFields["email"] :  a.email}
                editfield={a.userId}
                settername="email"
                onChange={updateField}
              />
            </Control>
            
            <Label htmlFor="last-name">
                    Poste
            </Label>
            <Control>
                  <select filterSelect="true" settername="job" editfield={a.userId} onChange={updateField} class="btnFilter" id="jobeFilter" value={editFields["userId"] == a.userId ? editFields["job"] :  a.job}>
                    <option value="">Poste</option>
                    {jobList.map( (j,i) => {
                      return <option value={j} >{j}</option>
                    })}
                  </select>
                    <div class="btnFilter bgWhite ml-0" onClick={showInput}>+</div>
                    <Input
                      id="job"
                      placeholder="Poste"
                      value={editFields["userId"] == a.userId ? editFields["job"] :  a.job}
                      editfield={a.userId}
                      settername="job"
                      onChange={updateField}
                      className="max-width-75 display-none"
                    />
            </Control>

            <Label htmlFor="last-name">
                    Equipe
            </Label>
            <Control>
                  <select filterSelect="true" settername="teamName" editfield={a.userId} onChange={updateField} class="btnFilter" id="jobeFilter" value={editFields["userId"] == a.userId ? editFields["teamName"] :  a.team.teamName}>
                    <option value="">Equipe</option>
                    {teamList.map( (j,i) => {
                      return <option value={j} >{j}</option>
                    })}
                  </select>
                    <div class="btnFilter bgWhite ml-0" onClick={showInput}>+</div>
                    <Input
                      id="Equipe"
                      placeholder="Equipe"
                      value={editFields["userId"] == a.userId ? editFields["teamName"] :  a.team.teamName}
                      editfield={a.userId}
                      settername="teamName"
                      onChange={updateField}
                      className="max-width-75 display-none"
                    />
            </Control>

            <Label htmlFor="last-name">
                    Magasin
            </Label>
            <Control>
                  <select filterSelect="true" settername="location" editfield={a.userId} onChange={updateField} class="btnFilter" id="magasinFilter" value={editFields["userId"] == a.userId ? editFields["location"] :  a.team.location}>
                    <option value="">Magasin</option>
                    {locationList.map( (j,i) => {
                      return <option value={j} >{j}</option>
                    })}
                  </select>
                    <div class="btnFilter bgWhite ml-0" onClick={showInput}>+</div>
                    <Input
                      id="Magasin"
                      placeholder="Magasin"
                      value={editFields["userId"] == a.userId ? editFields["location"] :  a.team.location}
                      editfield={a.userId}
                      settername="location"
                      onChange={updateField}
                      className="max-width-75 display-none"
                    />
            </Control>

            <br />
            <Button color="success" editfield={a.userId} onClick={save} /*disabled={!edited}*/>
              Sauver
            </Button>
          </Column>
        </Columns>
      </Box>
    ):""}

              </div>
            </div>
            </Column>
          </Columns>
        </Container>
      </Section>
    </div>
  );
}




