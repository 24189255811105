// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Main-root--E5RG0 {\n  margin-top: 44px;\n  min-height: calc(100vh - 134px);\n}\n", "",{"version":3,"sources":["webpack://./client/components/environment/Main/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,+BAA+B;AACjC","sourcesContent":[".root {\n  margin-top: 44px;\n  min-height: calc(100vh - 134px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Main-root--E5RG0"
};
export default ___CSS_LOADER_EXPORT___;
