import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import R from 'ramda';
import { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons/faRotate';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';

import Section from 'react-bulma-companion/lib/Section';
import Container from 'react-bulma-companion/lib/Container';
import Title from 'react-bulma-companion/lib/Title';
import Columns from 'react-bulma-companion/lib/Columns';
import Column from 'react-bulma-companion/lib/Column';
import Box from 'react-bulma-companion/lib/Box';
import Icon from 'react-bulma-companion/lib/Icon';
import Button from 'react-bulma-companion/lib/Button';
import Image from 'react-bulma-companion/lib/Image';
import Field from 'react-bulma-companion/lib/Field';
import Control from 'react-bulma-companion/lib/Control';
import Textarea from 'react-bulma-companion/lib/Textarea';
import Label from 'react-bulma-companion/lib/Label';
import Help from 'react-bulma-companion/lib/Help';
import Input from 'react-bulma-companion/lib/Input';
import '../../../main.css';



import Login from '_components/organisms/Login';



export default function UploadFile() {
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));
  const [file, setFile] = useState();
  const [description, setDescription] = useState("");
  const [image, setImage] = useState();
  const [imageList, setImageList] = useState([]);

  const getImageList = function(){
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: "{}"
    };
    let imagelst = fetch('/api/uploadfile/imagelist',requestOptions).then(response => response.json())
    .then(data => {
      console.log(data)
      setImageList(data)
    })
  }
  useEffect(() => {
    if (R.isEmpty(user)) {
      dispatch(push('/login'));
    }
    getImageList();
  }, [dispatch, user]);


  const submit = async event => {
    event.preventDefault()
    const formData = new FormData()
    formData.append("filename", description)
    formData.append("img", file)
    const result = await fetch('/api/uploadfile/upload-file', { body:formData, method:"POST"})
    getImageList();
  }

  return (
    <Section>
        <Container>
          <Columns>
            <Column size="12">
              <Box className="general-profile">
      <Icon size="medium" pull="right">
        <FontAwesomeIcon icon={faRotate} size="lg" />
      </Icon>
      <Title size="3">
        Uploader une image
      </Title>
      <form onSubmit={submit}>
        <label htmlFor="filename">
          Nom du fichier
        </label>
        <br />
      <input color="success"
          onChange={e => setDescription(e.target.value)} 
          type="text"
          name="filename"
        ></input>

        <input
          color="success"
          filename={file} 
          onChange={e => setFile(e.target.files[0])} 
          type="file" 
          name="img"
          accept="image/*"
        ></input>
        <br /><br />
        <Button type="submit" color="success">Upload</Button>
      </form>
      <hr />
      <Title>File list</Title>
      {imageList.map(a => {
        return <div className='imageListItem'>{window.location.origin + "/" + a}</div>
      })}
      </Box>
      </Column>
      </Columns>
      </Container>
    </Section>
  )
}
