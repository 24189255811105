import React, { useEffect, useRef, useState } from 'react';
import * as pdfjs from 'pdfjs-dist/build/pdf.mjs';
import * as pdfWorker from 'pdfjs-dist/build/pdf.worker.mjs';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

const PDFViewer = ({pageToList, setPageToList}) => {

    
    const [pdfDoc, setPdfDoc] = useState(null);
    const [pageNum, setPageNum] = useState(1);
    const [pageRendering, setPageRendering] = useState(false);
    const [pageNumPending, setPageNumPending] = useState(null);
    const [numPages, setNumPages] = useState(0);
    const [fileData, setFileData] = useState(null);
    const [reloader, setReloader] = useState(0);

    if(!pageToList && !setPageToList){
        const [pageToList, setPageToList] = useState([]);
    }
    const canvasRef = useRef(null);
  
    const handleFileChange = async (event) => {
        event.stopPropagation();
        event.preventDefault();
      const file = event.currentTarget.files[0];
      console.log(file);
      if (file) {
        const fileReader = new FileReader();
        fileReader.onload = function (e) {
          const typedArray = new Uint8Array(e.currentTarget.result);
          
          // to url 
          const url = URL.createObjectURL(new Blob([typedArray], { type: 'application/pdf' }));
        
          loadDocumentFromData(url);
        };
        fileReader.readAsArrayBuffer(file);
      }
    };
  
    const loadDocumentFromData = async (data) => {
       console.log(data);
      try {
        const loadingTask = pdfjs.getDocument({ url:data });
        // loadingTask.promise.then(pdf => {
        //     console.log(pdf);
        //     console.log('coucou');
        //     setPdfDoc(pdf);
        //     setNumPages(pdf.numPages);
        //     renderPage(1, pdf);
        // });
        
        const pdf = await loadingTask.promise;
        setPdfDoc(pdf);
        setNumPages(pdf.numPages);
        renderPage(1, pdf);
      } catch (error) {
        console.error('Error loading PDF:', error);
        alert('The selected file could not be loaded as a PDF.');
      }
    };
  
    const renderPage = async (num, pdf) => {
      setPageRendering(true);
      const page = await pdf.getPage(num);
      const viewport = page.getViewport({ scale: 2 });
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
  
      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
  
      const renderTask = page.render(renderContext);
      await renderTask.promise;
  
      setPageRendering(false);
      if (pageNumPending !== null) {
        renderPage(pageNumPending, pdf);
        setPageNumPending(null);
      }
    };
  
    const queueRenderPage = (num) => {
      if (pageRendering) {
        setPageNumPending(num);
      } else {
        renderPage(num, pdfDoc);
      }
    };
  
    const onPrevPage = () => {
      if (pageNum <= 1) return;
      setPageNum((prevPageNum) => {
        const newPageNum = prevPageNum - 1;
        queueRenderPage(newPageNum);
        return newPageNum;
      });
    };
  
    const onNextPage = () => {
      if (pageNum >= numPages) return;
      setPageNum((prevPageNum) => {
        const newPageNum = prevPageNum + 1;
        queueRenderPage(newPageNum);
        return newPageNum;
      });
    };
  
    const addPageToList = () => {
        setReloader(Math.random());
        if(!pageNum){
            return;
        }
        // check if page is already in list
        if(pageToList.find(page => page.pageNum === pageNum)){
            return;
        }
        // check if pageList < 4
        if(pageToList.length >= 4){
            return;
        }
        pageToList.push({ pageNum, base64Image: canvasRef.current.toDataURL() });
        // sort pageToList by pageNum asc
        pageToList.sort((a, b) => a.pageNum - b.pageNum);
        pageToList = pageToList.map(a => a);
        setPageToList(pageToList);
    }
    const removePageFromList = (ev) => {
        setReloader(Math.random());
        ev.stopPropagation();
        ev.preventDefault();
        if(ev.currentTarget.getAttribute("pageNum")){
            pageToList = pageToList.filter(page => page.pageNum != ev.currentTarget.getAttribute("pageNum"));
            setPageToList(pageToList);
            return;
        }
        if(!pageNum){
            return;
        }
        const newPageList = pageToList.filter(page => page.pageNum !== pageNum);
        setPageToList(newPageList);
    }
    const closeView = () => {
        setPdfDoc(null);
        setNumPages(0);
        setPageNum(1);
        setPageRendering(false);
        setPageNumPending(null);
    }
    const previewImg = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        // set class + previewPdfImgActive 
        //ev.currentTarget.classList.toggle('previewPdfImgActive');
        // set parent class + pdfCont
        ev.currentTarget.parentElement.classList.toggle('pdfCont');
        ev.currentTarget.parentElement.classList.toggle('pdfCanvasCont');
        ev.currentTarget.parentElement.classList.toggle('previewPdfPage');
        
    }
    return (
    <div>
        <input type="file" accept="application/pdf" onInput={handleFileChange}/>
        {pageToList.length && (
            <div>
                <h3>Pages sélectionnées</h3>
                <ul>
                    {pageToList.map(page => {
                    return <div className='previewPdfPage'>
                            <img className='previewPdfImg' onClick={previewImg} src={page.base64Image}/>
                            <button onClick={removePageFromList} pageNum={page.pageNum}>Supprimer</button>
                           </div>
                    })}
                </ul>
            </div>
        )}

        <div className={'pdfCont ' + (pdfDoc ? "" : "display-none")}>        
            {pdfDoc && (
            <div>
                <button onClick={onPrevPage} disabled={pageNum <= 1}>
                Previous
                </button>
                <button onClick={onNextPage} disabled={pageNum >= numPages}>
                Next
                </button>
                &nbsp; &nbsp;
                <span>
                Page: <span>{pageNum}</span> / <span>{numPages}</span>
                </span>
                { pageToList.find( a => a.pageNum == pageNum ) ? 
                    <button onClick={removePageFromList}>Retirer Page</button> : 
                    <button onClick={addPageToList}>Ajouter Page</button>
                }
                <button onClick={closeView}>Fermer</button>
            </div>
            )}
            <div className="pdfCanvasCont">
                <canvas ref={canvasRef} id="the-canvas"></canvas>
            </div>
        </div>
    </div>
    );
  };
  
  export default PDFViewer;