import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PDFViewer from '_components/organisms/PdfViewer';

import { push } from 'redux-first-history';
import R from 'ramda';

import { validateName } from '_utils/validation';
import { attemptGetUser, attemptUpdateUser, notif } from '_store/thunks/user';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons/faRotate';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons/faPenToSquare';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';

import Section from 'react-bulma-companion/lib/Section';
import Container from 'react-bulma-companion/lib/Container';
import Title from 'react-bulma-companion/lib/Title';
import Columns from 'react-bulma-companion/lib/Columns';
import Column from 'react-bulma-companion/lib/Column';
import Box from 'react-bulma-companion/lib/Box';
import Icon from 'react-bulma-companion/lib/Icon';
import Button from 'react-bulma-companion/lib/Button';
import Image from 'react-bulma-companion/lib/Image';
import Field from 'react-bulma-companion/lib/Field';
import Control from 'react-bulma-companion/lib/Control';
import Textarea from 'react-bulma-companion/lib/Textarea';
import Label from 'react-bulma-companion/lib/Label';
import Help from 'react-bulma-companion/lib/Help';
import Input from 'react-bulma-companion/lib/Input';


import styles from './styles.module.css';
import '../../../main.css';
import { el, fi } from 'date-fns/locale';

export default function UseListPage(props) {
  props = {};
  props.prefilled = {
    // "idd":"randomId",
    // "questionTitle": "bonjour c'est une question",
    // "choice1": "a ",
    // "choice2": "b",
    // "choice3": "c",
    // "choice4": "ddd",
    "goodAnswer": null
}
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));
  const [fields, setFields] = useState(props && props.prefilled ? {...props.prefilled} : {});
  const [reloadState, setReloadState] = useState(Math.random());
  const [editFields, setEditFields] = useState({});

  const [visibleElm, setVisibleElm] = useState({});

  // PDF Viewer
  const [pageToList, setPageToList] = useState([]);

  const [userList, setUserList] = useState([]);
  const [showEdit, setShowEdit] = useState('');
  
  const [teamList, setTeamList] = useState([]);

  const [questionGen, setQuestionGen] = useState({});
  const [formatedResult, setFormatedResult] = useState("");
  const [FormatedContent,setFormatedContent] = useState("");
  const [questionLoading, setQuestionLoading] = useState(false);
  
  
  const [questionTopic, setQuestionTopic] = useState([]);
  const [contents, setContents] = useState({});
  const [addField, setAddField] = useState({});
  const [prefilled, setPrefilled] = useState(false);

  let [keepTheme, setKeepTheme] = useState(false);
  const [questionList, setQuestionList] = useState([]);


  const hello = ()=>{
    console.log(fields)
  };
  // function generate id
  const generateId = () => {
    return Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9);
  };
  const resetState = useCallback(() => {
    if(props && props.prefilled && !prefilled){
      setPrefilled(true);
      setFields(fields);
    }else
      setFields({});
  }, [user]);

  const clearFields = () => {
      fields["goodAnswer"] = null;
      fields["contentTitle"] = "";

      if(!keepTheme){
        fields["contentTopic"] = "";
        fields["contentTopicId"] = "";
        fields["contentType"] = "";
      }
      if(formatedResult || fields.formatedContent){
        setFormatedResult("");
        fields["formatedContent"] = "";
      }
      fields["editMode"] = false;
      fields["contentId"] = "";
      fields["content"] = "";
      fields["idd"] = "";
      setFields(fields);
  }
  useEffect(() => {
    resetState();
  }, [user, resetState, reloadState]);





  const showInput = e => {
    let nextInput = e.target.nextSibling;
    if(!e.target.showed){
      e.target.showed = true;
      nextInput.classList.remove("display-none");
    }else{
      e.target.showed = false;
      nextInput.classList.add("display-none");
    }
  }

  const updateCheckBox = e => {
    console.log('updateCheckBox')
    const setterName = e.target.getAttribute("settername");
    const checkboxs = document.getElementsByClassName("checkboxQuestion");
    
    if(e.target.checked){
      // uncheck all other
      if(fields.goodAnswer != null){
        checkboxs[fields.goodAnswer * 1].checked = false;
      }
      updateField(e);
    }else{
      if(fields.goodAnswer == e.target.value){
        fields.goodAnswer = null;
        setFields(fields);
      }
    }
  }



  const packContent = e => {
    let content = {};
    let errors = [];

    if(fields.contentTitle && fields.contentTitle.trim()){
      content.contentTitle = fields.contentTitle.trim();
    }else{
      errors.push("Veuillez remplir le titre du contenu");
    }
    // check if content type is set
    if(fields.contentType && fields.contentType.trim()){
      content.contentType = fields.contentType.trim();
    }else{
      errors.push("Veuillez selectionner un type de contenu");
    }
    // check if content is set
    if(fields.content && fields.content.trim()){
      content.content = fields.content.trim();
    }else{
      errors.push("Veuillez remplir le contenu");
    }
    if(FormatedContent){
      content.formatedContent = FormatedContent;
    }


    if(errors.length){
      notif(errors[0],"danger");
      console.log(errors)
      return false;
    }
    if(!fields.idd){
      content.idd = generateId();
      fields["idd"] = content.idd;
      setFields(fields);
    }

    if(fields.editMode){
      content.editMode = true;
    }
    // post question 
    if(content.editMode){
      content.contentId = fields.contentId;
      postData(content,'content/edit-content',function(lol){
        clearFields();
        postData({getAll:true},'content/get-content',cbackSetter);
      })
    }else{
      postData(content,'content/add-content',function(lol){
        clearFields();
        postData({getAll:true},'content/get-content',cbackSetter);
      })
    }
    return content;
  }

  const updateField = e => {
      const setterName = e.target.getAttribute("settername");
      const isEditField = e.target.getAttribute("editfield");

      if(!isEditField){
        fields[setterName] = e.target.value;
        setFields(fields);
      }else{
        if(editFields.userId != isEditField){
          Object.keys(editFields).map(k => editFields[k] = '');
          userList.map(a => {
            if(a.userId == isEditField){
              Object.keys(a).map(k => editFields[k] = a[k]);
            }
          })
        }
        editFields[setterName] = e.target.value;
        setEditFields(editFields);
      }
  }

  const updateGen = e => {
    // get value
    questionGen.value = e.target.value;
    setQuestionGen(questionGen);
  }

  const generateFormat = (ev) => {

    ev.stopPropagation();
    ev.preventDefault();
    // get value
    if(questionLoading){
      return;
    }
    let value = fields.content;
    if(value && value.trim()){
      setQuestionLoading(ev.currentTarget);
      postData({context:value},'content/generate-format',function(data){
        if(data && data.data && data.data.length){
          setFormatedResult(data.data);
          setQuestionLoading(null);
        }
      })
    }
  }
  
  const copyClipboard = (ev) => {
    navigator.clipboard.writeText(ev.target.innerText);
    notif("Copié dans le presse-papier!","success")
  }

  const refresh = () => dispatch(attemptGetUser())
    .then(resetState)
    .catch(R.identity);



  const cbackSetter = (data) => {
      if(data && data.map){
        data.map(a => {
          if(a.contentId){
            contents[a.contentId] = a;
          }
        })
      }else{
        console.log("no map")
        console.log(data);
      }
      setContents(contents);
  }

  const postData = (datas,slug,callback) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(datas) //fields
    };
    fetch(window.location.origin +'/api/' + slug, requestOptions)
    .then(response => response.json())
    .then(data => {
      setTimeout(function(){console.log(userList)},1000)
      if(data)
        callback(data);
      if(data.message)
        notif(data.message,"success");
      if(data.messageError)
        notif(data.messageError,"danger");
    });
  }

  const deleteContent = (ev) => {
    ev.stopPropagation();
    let contentId = ev.currentTarget.getAttribute("contentId");
    if(contentId){
      delete contents[contentId];
      postData({contentId},'content/delete-content',function(lol){
        clearFields();  
        postData({getAll:true},'content/get-content',cbackSetter);
      })
    }
  }


  const showEditMenu = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    let contentId = ev.currentTarget.getAttribute("contentId");
    let fieldSet = {};
    let content = contents[contentId];
    Object.keys(content).map(a => {
      fieldSet[a] = content[a];
    })
    fieldSet.contentId = contentId;
    fieldSet.editMode = true;
    setFields(fieldSet);
  }

  function cleanHtml(htmlString) {
    const eventAttributes = [
      'onClick', 'onDblClick', 'onMouseDown', 'onMouseUp', 'onMouseMove',
      'onMouseOver', 'onMouseOut', 'onContextMenu', 'onKeyDown', 'onKeyPress',
      'onKeyUp', 'onSubmit', 'onChange', 'onInput', 'onSelect', 'onReset',
      'onFocus', 'onBlur', 'onLoad', 'onUnload', 'onResize', 'onScroll', 'onError',
      'onCopy', 'onCut', 'onPaste', 'onAbort', 'onCanPlay', 'onCanPlayThrough',
      'onDurationChange', 'onEmptied', 'onEnded', 'onLoadedData', 'onLoadedMetadata',
      'onLoadStart', 'onPause', 'onPlay', 'onPlaying', 'onProgress', 'onRateChange',
      'onSeeked', 'onSeeking', 'onStalled', 'onSuspend', 'onTimeUpdate',
      'onVolumeChange', 'onWaiting', 'onDrag', 'onDragEnd', 'onDragEnter', 'onDragLeave',
      'onDragOver', 'onDragStart', 'onDrop', 'onToggle', 'onWheel', 'onTouchStart',
      'onTouchMove', 'onTouchEnd', 'onTouchCancel'
    ];
    // Create a DOM parser
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
  
    // Remove all script elements
    const scripts = doc.querySelectorAll('script');
    scripts.forEach(script => script.remove());
  
    // Get all elements in the document
    const elements = doc.getElementsByTagName('*');
  
    // Iterate through all elements
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
  
      // Remove event attributes
      eventAttributes.forEach(event => {
        if (element.hasAttribute(event)) {
          element.removeAttribute(event);
        }
      });
  
      // Remove JavaScript URLs
      ['href', 'src', 'action'].forEach(attr => {
        if (element.hasAttribute(attr)) {
          const value = element.getAttribute(attr);
          if (value && value.trim().toLowerCase().startsWith('javascript:')) {
            element.removeAttribute(attr);
          }
        }
      });
    }
    // Return the modified HTML as a string
    return doc.body.innerHTML;
  }

  const setFormatedContentValue = () => {
    setFormatedContent(formatedResult);
  }

  const toggleVisible = (ev) => {
    // prevent default
    ev.stopPropagation();
    ev.preventDefault();
    let contentId = ev.currentTarget.getAttribute("contentId");
    if(visibleElm[contentId]){
      visibleElm[contentId] = false;
    }else{
      visibleElm[contentId] = true;
    }
    setVisibleElm(visibleElm);
  }


  const extractTextFromPdf = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    // blinking until we get the result
    document.getElementById("extractTextFromPdf").classList.toggle("blinkOpa");
    setQuestionLoading(ev.currentTarget);
    // we send the pageToList to the server
    postData({pageToList},'content/extract-text-from-image',function(data){
      // remove the blinking from the button
      document.getElementById("extractTextFromPdf").classList.toggle("blinkOpa");
      setQuestionLoading(false);
      if(data && data.data && data.data.length){
        // we set the text in the input content field by copying the text
        fields.content = data.data[0];
        setFields(fields);
      }
    })
  }

  const addGenQuestionToEditMenu = (ev) => {
      ev.stopPropagation();
      ev.preventDefault();
      let fieldSet = {};
      let quest = questionResults[ev.currentTarget.getAttribute("genIndex")];
      Object.keys(quest).map(a => {
        fieldSet[a] = quest[a];
        if(a == "questionChoices"){
          quest[a].map((b,i) => {
            fieldSet["choice"+(i+1)] = b;
          })
        }
      })
      // check if keepTheme is on
      if(!keepTheme){
        fieldSet.questionTopic = "";
        fieldSet.questionTopicId = "";
      }else{
        fieldSet.questionTopic = fields.questionTopic;
        fieldSet.questionTopicId = fields.questionTopicId;
      }
      setFields(fieldSet);
  }

  const keepThemeOn = (ev) => {
    
    if(!keepTheme){
        keepTheme = true;
    }
    else{
        keepTheme = false;
    }
    setKeepTheme(keepTheme);
  }



  useEffect(() => {
    postData({getAll:true},'content/get-content',cbackSetter);
  },[userList.length]);
  
  return (
    <div className="settings-page page">
      <Section>
        <Container>
          <Columns>
            <Column size="12">
            <div className="profile-settings">
              <div className="account-settings">
              <Box className="general-profile">
        
      <Title size="3">
        Créer un Contenu
      </Title>
      <hr className="separator" />
      <Columns size="12">
        <Column size="6" className="padding-20">
                <Label htmlFor="contentTitle" className="Label">
                  Titre du contenu
                </Label>
                <Control>
                  <Input
                    id="contentTitle"
                    placeholder="Ajouter un Titre"
                    settername="contentTitle"
                    value={fields.contentTitle}
                    onChange={updateField}
                    className="max-width-75 padding-20"
                  />
                </Control>
                <br />
                <Label htmlFor="contentContent" className="Label">
                  Contenu (300 caractères maximum)
                </Label>
                <Control>
                  <Textarea
                    id="questionTitle"
                    placeholder="Corps du contenu"
                    settername="content"
                    value={fields.content}
                    onChange={updateField}
                    className="max-width-75 padding-20"
                  />
                </Control>
                <br />
                <Button color="success" className={"mt-20 " + (questionLoading ? "blinkOpa" : "")} onClick={generateFormat}>Generer un formatage</Button>
          <Columns>
            <Column size="12" className="padding-20">
                <Field>
                  <Label htmlFor="last-name">
                    Content Type
                  </Label>
                  <Control>
                  <select filterSelect="true" onChange={updateField} class="btnFilter" settername="contentType" id="jobeFilter" value={fields.contentType}>
                    <option value="">Type de contenu</option>
                      <option value="text" >Text</option>
                      <option value="image" >Image</option>
                      <option value="video" >Video</option>
                  </select>
                    <Input
                      id="contentType"
                      placeholder="Type de contenu"
                      value={fields.contentType}
                      settername="contentType"
                      onChange={updateField}
                      className="max-width-75 display-none"
                    />
                  </Control>
                </Field>
              </Column>
          </Columns>
        </Column>
        <Column size="6" className={"padding-20"}>
          <PDFViewer pageToList={pageToList} setPageToList={setPageToList} />
          {
            pageToList.length ? 
            <Button color="success" onClick={extractTextFromPdf} id="extractTextFromPdf">Extraire Texte</Button> : ""
          }
        </Column>
        <Column size="6" className={"padding-20 " + (!formatedResult && !fields.formatedContent ? "display-none" : "")}>
                  <Label htmlFor="contentTitle" className="Label">
                    Version Html
                  </Label>
                  <div className='GenResultsCont prevContent' id="GenResultsCont">
                    {formatedResult || fields.formatedContent ? 
                      <div dangerouslySetInnerHTML={{ __html: formatedResult || fields.formatedContent}} />
                      : ""
                    }
                  </div>
                  <Button color="success" className={"mt-20" + (questionLoading ? "blinkOpa" : "")} onClick={setFormatedContentValue}>Utiliser</Button>
        </Column>
      </Columns>
      <hr className="separator" />
      <Button color="success" onClick={packContent} /*disabled={!edited}*/>
      Sauver
      </Button>
    </Box>
              </div>
            </div>
            </Column>
          </Columns>
        </Container>
      </Section>
      <Section>
        <Container>
          <Columns>
          <Column size="12">
            <div className="profile-settings">
              <div className="account-settings">
              <Box className="general-profile">
                <Title size="3">
                  Content List
                </Title>
                <hr className="separator" />
                <Columns>
                  <Column size="6" className="padding-20">
                    {contents ? Object.keys(contents).map( (a) => {
                      a = contents[a];
                      return <div className="quizTitle" data-idd={a.contentId}>
                        <h2>{a.contentTitle}
                          <div className="inline-block ml-2" onClick={deleteContent} contentId={a.contentId} >
                          <FontAwesomeIcon icon={faTrashCan} className='icon'/>
                          <FontAwesomeIcon icon={faPenToSquare} className='icon' onClick={showEditMenu} contentId={a.contentId}/>  
                          <FontAwesomeIcon icon={faEye} className='icon' onClick={toggleVisible} contentId={a.contentId} />
                          </div>
                        </h2>
                        {a.contentType == "text" && visibleElm[a.contentId] ? 
                              <div className="contentText prevContent" dangerouslySetInnerHTML={{ __html: cleanHtml(a.formatedContent || a.content)}} />
                        : ""}
                      </div>
                    }) : ""}
                  </Column>
                </Columns>
              </Box>
              </div>
            </div>
            </Column>
          </Columns>
        </Container>
      </Section>
    </div>
  );
}




