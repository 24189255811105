// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer-root--wG4Ma {\n  background-color: #c5c5c5;\n  padding: 3rem 1.5rem;\n\n}\n\n.Footer-content--oFO85 a:visited {\n  color: #000;\n}\n", "",{"version":3,"sources":["webpack://./client/components/organisms/Footer/styles.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,oBAAoB;;AAEtB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".root {\n  background-color: #c5c5c5;\n  padding: 3rem 1.5rem;\n\n}\n\n.content a:visited {\n  color: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Footer-root--wG4Ma",
	"content": "Footer-content--oFO85"
};
export default ___CSS_LOADER_EXPORT___;
